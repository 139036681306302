let images = [
	["./assets/images/virdis/jpg/IMG_20230203_0003.jpg", "", "Porto Torres, 1991, Altezza 50m, Ponteggio a Tubi e Giunti su Toricia Enichem VCM"],
	["./assets/images/virdis/jpg/IMG_20230203_0005.jpg", "", "Ozieri, Fiera del bestiame, 1989, Ponteggio a Tubi e Giunti per tribuna ospiti 350"],
	["./assets/images/virdis/jpg/IMG_20230203_0006.jpg", "", "Alghero, 2010, Ponteggio appeso, misto Tubo-Giunto e Telaio Prefabbricato marchio Marcegaglia"],
	["./assets/images/virdis/jpg/IMG_20230203_0008.jpg", "", "Murta Maria, 1988, Lunghezza 120m, Ponte sul fiume Padrongianus ponteggio a Tubi e Giunti"],
	["./assets/images/virdis/jpg/IMG_20230203_0010.jpg", "", "Ozieri, Fiera del bestiame, 1989, Ponteggio a Tubi e Giunti per tribuna ospiti 350"],
	["./assets/images/virdis/jpg/IMG_20230203_0012.jpg", "", "La Maddalena, 2010, Ponteggio a telai prefabbricati Marcegaglia, modello T-5, presenza di sbalzi laterali"],
	["./assets/images/virdis/jpg/IMG_20230203_0013.jpg", "", "Porto Torres, 1991, Altezza 50m, Ponteggio a Tubi e Giunti su Toricia Enichem VCM"],
	["./assets/images/virdis/jpg/IMG_20230203_0014.jpg", "", "Alghero, 2010, Ponteggio appeso, misto Tubo-Giunto e Telaio Prefabbricato marchio Marcegaglia"],
	["./assets/images/virdis/jpg/IMG_20230203_0015.jpg", "", "Porto Torres, 1999, Ponte su roccia nei pressi di Balai, Ponteggio realizzato a Tubi e Giunti"],
	["./assets/images/virdis/jpg/IMG_20230203_0016.jpg", "", "Alghero, 2010, Ponteggio appeso, misto Tubo-Giunto e Telaio Prefabbricato marchio Marcegaglia"],
	["./assets/images/virdis/jpg/IMG_20230203_0017.jpg", "", "Murta Maria, 1988, Lunghezza 120m, Ponte sul fiume Padrongianus ponteggio a Tubi e Giunti"],
	["./assets/images/virdis/jpg/IMG_20230203_0018.jpg", "", "Porto Torres, 1999, Ponte su roccia nei pressi di Balai, Ponteggio realizzato a Tubi e Giunti"],
	["./assets/images/virdis/jpg/IMG_20230203_0019.jpg", "", "Li Punti, Campanile Parrocchia San Pio X, 2004, Ponteggio Marcegaglia Realpont"],
	["./assets/images/virdis/jpg/IMG_20230203_0021.jpg", "", "Porto Torres, 1991, Altezza 50m, Ponteggio a Tubi e Giunti su Toricia Enichem VCM"],
	["./assets/images/virdis/jpg/IMG_20230203_0022.jpg", "", "Chilivani, 1994, Altezza massima 50m, Cisterna acqua pensile, ponteggio Tubi e Giunti"],
	["./assets/images/virdis/jpg/IMG_20230203_0024.jpg", "", "Sassari, 2010, Altezza 22m, Cistema acqua pensile, ponteggio a telai prefabbricati Marcegaglia, T-5"],
	["./assets/images/virdis/jpg/IMG_20230203_0025.jpg", "", "Sassari, 2013, Altezza 40 metri, Campanile del Duomo di Sassari, ponteggio Goff"],
	["./assets/images/virdis/jpg/IMG_20230203_0026.jpg", "", "Alghero, 2010, Ponteggio appeso, misto Tubo-Giunto e Telaio Prefabbricato marchio Marcegaglia"],
	["./assets/images/virdis/jpg/IMG_20230203_0027.jpg", "", "Porto Torres, 1987, Altezza 66m, Torri gemelle Sirben, ponteggio Tubi e Giunti"],
	["./assets/images/virdis/jpg/IMG_20230203_0028.jpg", "", "Porto Torres, 1991, Altezza 50m, Ponteggio a Tubi e Giunti su Toricia Enichem VCM"],
	["./assets/images/virdis/jpg/IMG_20230203_0030.jpg", "", "Sassari, 1992, Eliporto Ospedale Civile SS Annunziata, Ponteggio Messersì"],
	["./assets/images/virdis/jpg/IMG_20230203_0033.jpg", "", "Lago Coghinas, 1987, Diga dell'ivaso artificiale, altezza 80m, ponteggio a Tubi e Giunti"],
	["./assets/images/virdis/jpg/IMG_20230203_0034.jpg", "", "Lago Coghinas, 1987, Diga dell'ivaso artificiale, altezza 80m, ponteggio a Tubi e Giunti"],
	["./assets/images/virdis/jpg/IMG_20230203_0036.jpg", "", "Alghero, 2010, Ponteggio appeso, misto Tubo-Giunto e Telaio Prefabbricato marchio Marcegaglia"],
	["./assets/images/virdis/jpg/IMG_20230203_0038.jpg", "", "Lago Coghinas, 1987, Diga dell'ivaso artificiale, altezza 80m, ponteggio a Tubi e Giunti"],
	["./assets/images/virdis/jpg/IMG_20230203_0041.jpg", "", "Lago Coghinas, 1987, Diga dell'ivaso artificiale, altezza 80m, ponteggio a Tubi e Giunti"],
	["./assets/images/virdis/jpg/IMG_20230203_0042.jpg", "", "Porto Torres, 1991, Altezza 50m, Ponteggio a Tubi e Giunti su Toricia Enichem VCM"],
	["./assets/images/virdis/jpg/IMG_20230203_0043.jpg", "", "Porto Torres, 1991, Altezza 50m, Ponteggio a Tubi e Giunti su Toricia Enichem VCM"],
	["./assets/images/virdis/jpg/IMG_20230203_0046.jpg", "", "Porto Torres, 1999, Ponte su roccia nei pressi di Balai, Ponteggio realizzato a Tubi e Giunti"],
	["./assets/images/virdis/jpg/IMG_20230203_0047.jpg", "", "Porto Torres, 1999, Ponte su roccia nei pressi di Balai, Ponteggio realizzato a Tubi e Giunti"],
	["./assets/images/virdis/jpg/IMG_20230203_0048.jpg", "", "Sassari, 2013, Altezza 40 metri, Campanile del Duomo di Sassari, ponteggio Goff"],
	["./assets/images/virdis/jpg/IMG_20230203_0049.jpg", "", "Alghero, 2010, Ponteggio appeso, misto Tubo-Giunto e Telaio Prefabbricato marchio Marcegaglia"],
]

function generateBottomCarouselItem(image, alt, active = false) {
	let carouselItem = document.createElement("div");
	carouselItem.classList.add("carousel-item");
	if (active) {
		carouselItem.classList.add("active");
	}

	let img = document.createElement("img");
	img.src = image;
	img.alt = alt;
	img.classList.add("d-block");
	carouselItem.appendChild(img);

	let titoloImmagine = document.createElement('h5');
	titoloImmagine.className = 'carousel-caption d-none d-md-block';
	titoloImmagine.innerText = alt;
	titoloImmagine.style.background = "linear-gradient(to right, rgba(0, 0, 30, 0.5), rgba(0, 0, 30, 0.5))";

	carouselItem.appendChild(titoloImmagine)

	return carouselItem;
}


let bottomCarousel = document.querySelector("#bottomCarousel>.footer-carousel-inner");


images.forEach(image => {
	let bottomCarouselItem = generateBottomCarouselItem(image[0], image[2], images.indexOf(image) === 0);
	bottomCarousel.appendChild(bottomCarouselItem);
});

